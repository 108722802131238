<template>
    <v-container fluid fill-height class="primary" align="start" no-gutters>
            <v-row align="center" justify="center">
                <v-row align="center" justify="center">
                    <div style="display: flex; align-items: center">
                        <img
                            alt="Zewu"          
                            contain
                            src="@/assets/logo-navbar.png"
                            width="100"
                            class="mr-4"
                        />
                        <span class="logo-text display-3 white--text align-center">Zewu</span>
                    </div>
                </v-row>
                <v-col cols=12 align="center">
                    <span class="text-h6 white--text">Sistema de gestión de reuniones</span>
                </v-col>
                <v-col cols=12 align="center">
                    <v-btn tile @click.prevent="login(target)" ><span class="primary--text">INGRESAR AL SISTEMA</span></v-btn>
                </v-col>
            </v-row>
            <v-row/>
            <v-row align="center" justify="center">
                    <v-img src="@/assets/unc-pi-logo.png" alt max-height="60px" contain class="img-logo"/>
            </v-row>
    </v-container>
</template>


<script>
import { mapActions } from "vuex";

export default {
  name: "Login",
  computed: {
    target() {
      return this.$router.currentRoute?.query?.t ?? '';
    },
  },
  methods: {
    ...mapActions(["login"]),
  },
};
</script>

<style lang="scss" scoped>

.img-logo {
    -webkit-filter: invert(100%);
    filter: invert(100%);
}

</style>
